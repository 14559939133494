import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import i18n from 'i18next';
import './TravelIdeaTab.scss'
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

// image
// import idea_img from '../../assets/index/idea-imag.png';

// components
import TravelIdeaTabItem from '../TravelIdeaTabItem/TravelIdeaTabItem';

function TravelIdeaTab(props) {
    const { t } = useTranslation();
    const curLang = i18n.resolvedLanguage;
    const [activeTab, setTabChange] = React.useState({selected:"tab1"});
    // const [state, setState] = React.useState({ selected: "m1-3" });

    

    var tab_details_slider_settings = {
      dots: true,
      infinite: true,
      autoplay:false,
      autoplaySpeed:3000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows:2,
      slidesPerRow: 3,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            rows: 3,
            slidesPerRow:1
          }
        }
      ]
    };
/*  sample data format
  {
    item_image:idea_img,
    item_title: "Giraffe Manor & Kenya Fly-in Safari",
    item_place:"Nairobi, Laikipia, Masai Mara Nairobi, Laikipia, Masai Mara",
    item_desp: "From meeting the giraffes at your breakfast table in Nairobi to the spectacular annual wilderbeest migration in the glorious Masai Mara, this thrilling safari journery will bring you the most memorable experience of the wildest Kenya.",
    item_night:"6 Nights",
    item_price:"From HKD 54,880/adult"
  } 
*/
    let tab_items = [
      { 
        tab_title: "JAN - MAR", 
        tab_id: "tab1",
        tab_content:[]
      },
      { 
        tab_title: "APR - JUN", 
        tab_id: "tab2",
        tab_content:[]
      },
      { 
        tab_title: "JUL - SEP", 
        tab_id: "tab3",
        tab_content:[]
      },
      { 
        tab_title: "OCT - DEC", 
        tab_id: "tab4",
        tab_content:[]
      },
    ];

    tab_items.map(tabItem=>{
      tabItem.tab_content = [...props.tabItem.filter((item) => item.acf.display_tab == tabItem.tab_id)];
    });
    console.log("🚀 ~ tab_items:", tab_items);

    // for (let i = 0; i < 4; i++) {
    //   const curr_tab = "tab"+(i+1);
    //   item_list_result[i] = api_tab_item_list.filter((item) => item.acf.display_tab == curr_tab);
    // }
    
    const handleTabChange = (index) => {
      setTabChange({selected:index});
    }
    const handleTabChangeMb = (event) => {
      setTabChange({selected:event.target.value});
    }

    return (
      <div className="travel-idea-tab-section">
        <div className="tab-item-container">
          {tab_items.map((item,index) => {
            return <div key={index} className={`tab-item ${item.tab_id == activeTab.selected ? 'active':''}`} onClick={()=>handleTabChange(item.tab_id)}>{props.tabInfo[`${item.tab_id}_title`]}</div>;
          })}
        </div>
        <div className="tab-item-container-mb">
          <select className="item-selection" name="travalTabMb" id="travalTabMb" onChange={handleTabChangeMb}> 
          {tab_items.map((item,index) => { 
            return <option key={index} value={item.tab_id}>{props.tabInfo[`${item.tab_id}_title`]}</option>
          })}
          </select>
        </div>
        {tab_items.map((item,index) => {
          return (
            item.tab_content.length > 6 ? 
              <div key={index} className={`tab-details-container ${item.tab_id == activeTab.selected ? 'active':''}`}> 
              <Slider {...tab_details_slider_settings}>
                {item.tab_content.map((tabContentItem,index) => {
                  return (<div>
                    <div className="tab-content-slider-item" id={index}>
                    <TravelIdeaTabItem itemdetails={tabContentItem.acf}></TravelIdeaTabItem>
                    </div>
                  </div>);
                })}
              </Slider>
              </div>
            :
            <div key={index} className={`tab-details-container no-slider ${item.tab_id == activeTab.selected ? 'active':''}`}> 
              {item.tab_content.map((tabContentItem,index) => {
                return (
                  <div className="tab-content-slider-item" id={index}>
                    <TravelIdeaTabItem itemdetails={tabContentItem.acf}></TravelIdeaTabItem>
                  </div>);
              })}
            </div>
          );
        })}
      </div>
    );
  }
  
  export default TravelIdeaTab;