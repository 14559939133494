import footer_logo from '../../assets/common/footer_logo.png';
import footer_call from '../../assets/common/footer_call.png';
import footer_mail from '../../assets/common/footer_mail.png';

import React, { useState, useContext, useEffect  } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import './Footer.scss'
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

import { DataContext } from '../../App';

// images
import icon_fb_gold from '../../assets/icon_fb_gold.png';
import icon_ig_gold from '../../assets/icon_ig_gold.png';



function Footer() {
  const { t } = useTranslation();
  const allpagedata = useContext(DataContext);
  const [curLang, setCurLang] = useState(i18n.resolvedLanguage);
  const [desActive, setdesActive] = useState(false);
  const [itinerariesActive, setitinerariesActive] = useState(false);
  const [travelInfoActive, settravelInfoActive] = useState(false);
  const [aboutUsActive, setaboutUsActive] = useState(false);

  useEffect (()=>{
    setCurLang (i18n.resolvedLanguage);
  },[i18n.resolvedLanguage])
  
    return (
      <div className="footer-container">
        <div className="top-section">
          <img src={footer_logo} alt="" className="footer-logo"/>
          <div className="info-section">
            <div className="info-container call-info">
              <img src={footer_call} alt="" className="info-icon call-icon" />
              <div className="info-detail">{t('info.phone_num')}</div>
            </div>
            <div className="info-container mail-info">
              <img src={footer_mail} alt="" className="info-icon mail-icon" />
              <div className="info-detail">{t('info.email')}</div>
            </div>
          </div>
        </div>
        <div className="footer-line">
          <hr/>
        </div>
        <div className="page-section">
          <div className="page-container ">
            <div className={`page-title ${(desActive)?'active':''}`} onClick={(e)=>{e.preventDefault();(desActive)?setdesActive(false):setdesActive(true)}}>
              <span className="title-name">{t('destination')}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z" fill="#D9B783"/>
              </svg>
            </div>
            <div className={`page-item-container ${(desActive)?'active':''}`}>
              {(allpagedata && allpagedata.destination_afric_pagelist)?allpagedata.destination_afric_pagelist.map(
                (item, idx) => {
                  const [name,subname] = item.slug.split('_');
                  if(subname === undefined) {
                    return <div key={idx} className="page-item"><Link to={`/${curLang}/destination/africa/${name}/}`} className="page-item-link">{item.acf.page_url_name[curLang]}</Link></div>
                  }
                } 
              ):''}
              {(allpagedata && allpagedata.destination_rotw_pagelist)?allpagedata.destination_rotw_pagelist.map((item,idx)=>{
                  const [name,subname] = item.slug.split('_');
                  if(subname === undefined) {
                    return <div key={idx} className="page-item"><Link to={`/${curLang}/destination/rest_of_the_world/${name}/}`} className="page-item-link">{item.acf.page_url_name[curLang]}</Link></div>
                  }
              }):''}
            </div>
          </div>
          <div className="page-container">
            <div className={`page-title ${(itinerariesActive)?'active':''}`} onClick={(e)=>{e.preventDefault();(itinerariesActive)?setitinerariesActive(false):setitinerariesActive(true)}}>
              <span className="title-name">{t('itineraries')}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z" fill="#D9B783"/>
              </svg>
            </div>
            <div className={`page-item-container ${(itinerariesActive)?'active':''}`}>
              {(allpagedata && allpagedata.itineraries_pagelist)?allpagedata.itineraries_pagelist.experience.map((item,idx)=>{
                    return <div key={idx} className="page-item"><Link to={`/${curLang}/itineraries/${item.url}`} className="page-item-link">{item.name[curLang]}</Link></div>
                }):''}
              {(allpagedata && allpagedata.itineraries_pagelist)?allpagedata.itineraries_pagelist.destination.map((item,idx)=>{
                    return <div key={idx} className="page-item"><Link to={`/${curLang}/itineraries/${item.url}`} className="page-item-link">{item.name[curLang]}</Link></div>
                }):''}
            </div>
          </div>
          <div className="page-container">
            <div className={`page-title ${(travelInfoActive)?'active':''}`} onClick={(e)=>{e.preventDefault();(travelInfoActive)?settravelInfoActive(false):settravelInfoActive(true)}}>
              <span className="title-name">{t('traval_info')}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z" fill="#D9B783"/>
              </svg>
            </div>
            <div className={`page-item-container ${(travelInfoActive)?'active':''}`}>
              {(allpagedata && allpagedata.travel_info_pagelist)?allpagedata.travel_info_pagelist.map((item,idx)=>{
                const [name,subname] = item.slug.split('_');
                  return <div key={idx} className="page-item"><Link to={`/${curLang}/travel-info/${name}/${(subname)?subname:''}`} className="page-item-link">{item.acf.page_url_name[curLang]}</Link></div>
              }):''}
            </div>
          </div>
          <div className="page-container">
            <div className={`page-title ${(aboutUsActive)?'active':''}`} onClick={(e)=>{e.preventDefault();(aboutUsActive)?setaboutUsActive(false):setaboutUsActive(true)}}>
              <span className="title-name">{t('about_us')}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M4.21967 8.46967C4.51256 8.17678 4.98744 8.17678 5.28033 8.46967L12 15.1893L18.7197 8.46967C19.0126 8.17678 19.4874 8.17678 19.7803 8.46967C20.0732 8.76256 20.0732 9.23744 19.7803 9.53033L12.5303 16.7803C12.2374 17.0732 11.7626 17.0732 11.4697 16.7803L4.21967 9.53033C3.92678 9.23744 3.92678 8.76256 4.21967 8.46967Z" fill="#D9B783"/>
              </svg>
            </div>
            <div className={`page-item-container ${(aboutUsActive)?'active':''}`}>
            {(allpagedata && allpagedata.aboutus_pagelist)?allpagedata.aboutus_pagelist.map((item,idx)=>{
                  return <div key={idx} className="page-item"><Link to={`/${curLang}/aboutus/${item.slug}`} className="page-item-link">{item.acf.page_url_name[curLang]}</Link></div>
              }):''}
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <div className="social-section">
            <a href={t('info.fb_link')} target="_blank"  rel="noreferrer" className="ext-link-item fb-item">
              <div className="media-icon fb-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6.29985H15.6244H14.9936C14.4504 6.29985 14.0101 6.74015 14.0101 7.2833V10.7337H18L17.4156 14.5322H14.0101V21.5H9.62255V14.5322H6V10.7337H9.57575L9.62255 7.12815L9.6159 6.47355C9.59395 4.30109 11.3372 2.52217 13.5097 2.5002C13.5229 2.50007 13.5362 2.5 13.5494 2.5H18V6.29985Z" fill="white"/>
                </svg>
              </div>
            </a>
            <a href={t('info.ig_link')} target="_blank"  rel="noreferrer" className="ext-link-item ig-item">
              <div className="media-icon ig-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2092 4.79086 21 7 21H17C19.2092 21 21 19.2092 21 17V7C21 4.79086 19.2092 3 17 3ZM12 16C14.2092 16 16 14.2092 16 12C16 9.79085 14.2092 8 12 8C9.79085 8 8 9.79085 8 12C8 14.2092 9.79085 16 12 16ZM18.5 6.5C18.5 7.0523 18.0523 7.5 17.5 7.5C16.9477 7.5 16.5 7.0523 16.5 6.5C16.5 5.9477 16.9477 5.5 17.5 5.5C18.0523 5.5 18.5 5.9477 18.5 6.5Z" fill="white"/>
                </svg>
              </div>
            </a>
            <div className="text-item">FOLLOW US!</div>
          </div>
          <div className="copy-text">Copyright © 2019 Wild Senses Holidays</div>
        </div>
      </div>
    );
  }
  
  export default Footer;