import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

// import React, { useState } from 'react';
import './TravelIdeaTabItem.scss'

// images
import idea_wallet from '../../assets/index/idea_wallet.png';

function TravelIdeaTabItem(props) {
  // console.log("🚀 ~ TravelIdeaTabItem ~ props:", props)
  const { t } = useTranslation();
  const curLang = i18n.resolvedLanguage;

    // const fake_item_data ={
    //   image_url:idea_img,
    //   item_title: "Giraffe Manor & Kenya Fly-in Safari",
    //   item_place:"Nairobi, Laikipia, Masai Mara Nairobi, Laikipia, Masai Mara",
    //   item_desp: "From meeting the giraffes at your breakfast table in Nairobi to the spect`acular annual wilderbeest migration in the glorious Masai Mara, this thrilling safari journery will bring you the most memorable experience of the wildest Kenya.",
    //   item_night:"6 Nights",
    //   item_price:"From HKD 54,880/adult"
    // }

    return (
      <Link to={`/${curLang}/itineraries/${props.itemdetails.innerpage_url}`} className="item-link">
        <div className="travel-item">
          <div className="item-img-container"><img className="item-image" src={props.itemdetails.item_image} alt=""/></div>
          <div className="item-content">
            <div className="item-title">{props.itemdetails.item_title[curLang]}</div>
            <div className="item-place">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='place-icon'>
              < path d="M18.157 16.8823L16.97 18.0562C16.0951 18.9149 14.96 20.0188 13.5642 21.3684C12.6919 22.2118 11.3081 22.2117 10.436 21.3682L6.94489 17.9723C6.50612 17.5415 6.13886 17.1782 5.84302 16.8823C2.44261 13.4819 2.44261 7.96878 5.84302 4.56837C9.24343 1.16796 14.7566 1.16796 18.157 4.56837C21.5574 7.96878 21.5574 13.4819 18.157 16.8823ZM14.5002 11.0001C14.5002 9.61924 13.3808 8.49986 12 8.49986C10.6192 8.49986 9.49981 9.61924 9.49981 11.0001C9.49981 12.3809 10.6192 13.5003 12 13.5003C13.3808 13.5003 14.5002 12.3809 14.5002 11.0001Z"/>
              </svg>
              <spn className="place-text">{props.itemdetails.item_place[curLang]}</spn>
            </div>
            <div className="item-desp">{props.itemdetails.item_desp[curLang]}</div>
            <div className="item-night">{props.itemdetails.item_night[curLang]}</div>
            <div className="item-price"><img src={idea_wallet} className="wallet-icon"/>{props.itemdetails.item_price[curLang]}</div>
          </div>
        </div>
      </Link>
    );
  }
  
  export default TravelIdeaTabItem;