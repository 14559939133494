// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import './TravelInfoDetails.scss';
import i18n from 'i18next';

import {resources_callapi} from'../../common/CallApi';

// image
// import profile_img from '../../assets/travel_info_profile.png';

// components
import TravelIdeaTabItem from '../../components/TravelIdeaTabItem/TravelIdeaTabItem';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
// import MapTab from '../../components/MapTab/MapTab';


function TravelInfoDetails() {
    const { t } = useTranslation();
    const {pagename} = useParams();
    const [detailsContent, setDetailsContent] = React.useState([]);
    const [pageRecommendItemList, setpageRecommendItemList] = React.useState([]);
    // const [pageListActive, setPageListActive] = React.useState(false);
    // const [subPageListActive, setSubPageListActive] = React.useState({"faqs":false});
    // const [showOtherItem, setShowOtherItem] = React.useState({"faqs":false});
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    
    let location = useLocation();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;


    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }
      

      const get_page_content = async () => {
        // const api_page_slug = categories+'_'+pagename;
        const info_detauils_api_content = await resources_callapi("travel_info_details",pagename);
        let info_content = [];
        let recommend_item_content = [];
        if (info_detauils_api_content.length > 0 && info_detauils_api_content.find((item) => item.slug == pagename)) {
          info_content = info_detauils_api_content.find((item) => item.slug == pagename).acf;

          let info_child_items = Object.keys(info_content).filter(key => key.startsWith('child_item_')).map(key => ({...info_content[key],child_name: key}));
          info_content['info_child_items'] = info_child_items.filter(item => item.child_type !== "");

          recommend_item_content = await resources_callapi("travelbymontab","",`per_page=3&include=${info_content.recommend_item.join()}`)
        }

        Promise.all([ info_content, recommend_item_content]).then((content) => {
          console.log(content);
          setDetailsContent(content[0]);
          setpageRecommendItemList(content[1]);
        });
      }
      get_page_content();

      // const get_all_sub_pagelist = async () => {
      //   let pageList = [];
      //   const api_pageList = await resources_callapi("travel_info", "","per_page=100","page_list");

      //   api_pageList.map((pageListItem)=>{
      //     const [name,subname] = pageListItem.slug.split('_');
      //     pageList.push({'categorie':name,'url_name':name,'url_subname':subname,'name':pageListItem.acf.page_url_name});
      //   })
      //   setPageList(pageList);
      //   console.log("🚀 ~ constget_all_faqs_pagelist= ~ pageList:", pageList)

      // }

      // get_all_sub_pagelist();
      // const get_all_faqs_pagelist = async () => {
      //   let pageList = [];
      //   const api_pageList = await resources_callapi("travel_info_faq", "","per_page=100","page_list");

      //   api_pageList.map((pageListItem)=>{
      //     pageList.push({'url':pageListItem.slug,'name':pageListItem.acf.page_url_name});
      //   })
      //   setPageSubItems({...pageSubItems,"faqs_item":pageList});
      //   console.log("🚀 ~ constget_all_faqs_pagelist= ~ pageList:", pageList)

      // }
      // get_all_faqs_pagelist();

    }, [curPath]);


    return (

      (detailsContent && Object.keys(detailsContent).length > 0) ? (
        <div className="page-container">
          {/* <Helmet>
              <title>details</title>
          </Helmet> */}
          <div className={`info-details-page-container ${pagename}`}>

            <div className="banner-section" style={{background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),url(${(isMobile)?detailsContent.banner_img.mobile:detailsContent.banner_img.desktop}) lightgray 50% / cover no-repeat`}}>
              <div className="banner-text-section">
                <div className="page-title">
                  <div className="page-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                    </svg>
                  </div>
                  <div className="page-name">{(detailsContent.banner_page_title)?detailsContent.banner_page_title[curLang]:''}</div>
                </div>
                <div className="sub-title">{(detailsContent.banner_page_subtitle)?detailsContent.banner_page_subtitle[curLang]:''}</div>
              </div>
            </div>
            
            {/* <div className="mb-page-list-btn" onClick={(e)=>{ if(isMobile){setPageListActive(true);}}}>
              <div className="section-name">{(pageContent.content_page_title)?pageContent.content_page_title[curLang]:''}</div>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4.21967 15.5303C4.51256 15.8232 4.98744 15.8232 5.28033 15.5303L12 8.81066L18.7197 15.5303C19.0126 15.8232 19.4874 15.8232 19.7803 15.5303C20.0732 15.2374 20.0732 14.7626 19.7803 14.4697L12.5303 7.21967C12.2374 6.92678 11.7626 6.92678 11.4697 7.21967L4.21967 14.4697C3.92678 14.7626 3.92678 15.2374 4.21967 15.5303Z" fill="black"/>
                </svg>
              </div>
            </div> */}
            <ContactUs></ContactUs>
            <div className="content-section">
              {(detailsContent.author_name && detailsContent.author_name.en)?
                <div className={`page-info-section`}>
                  <div className="info-box-container">
                    <div className="profile-img-item">
                      <img src={(detailsContent.author_img)?detailsContent.author_img:''} alt="" className="profile-img" />
                    </div>
                    <div className="profile-name">{(detailsContent.author_name)?detailsContent.author_name[curLang]:''}</div>
                    <div className="line"></div>
                    <div className="page-date">10 Nov 2016</div>
                  </div>
                </div>
              :''}

              <div className={`content-details-section ${(detailsContent.author_name && detailsContent.author_name.en)?'':'full-width'}`}>
                <div className="details-sub-section">
                  <div className="title">{(detailsContent.content_page_title)?detailsContent.content_page_title[curLang]:''}</div>
                  <div className="line-section"><div className="line"></div></div>
                  <div className="sub-title">{(detailsContent.content_page_subtitle)?detailsContent.content_page_subtitle[curLang]:''}</div>
                  <div className="info-child-list">
                    {(detailsContent.info_child_items)?(detailsContent.info_child_items).map((child_item, idx)=>{
                      return <div key={idx} className={`info-item`}>
                        {(child_item.item_title[curLang] && child_item.item_title[curLang] !== "" )?
                          <div className="icon-title">
                            <div className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 6C13.6045 6 14.5 5.10455 14.5 4C14.5 2.89543 13.6045 2 12.5 2C11.3954 2 10.5 2.89543 10.5 4C10.5 5.10455 11.3954 6 12.5 6Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 4C9.75 2.48121 10.9812 1.25 12.5 1.25C14.0188 1.25 15.25 2.48121 15.25 4C15.25 5.51876 14.0188 6.75 12.5 6.75C10.9812 6.75 9.75 5.51876 9.75 4ZM12.5 2.75C11.8097 2.75 11.25 3.30965 11.25 4C11.25 4.69034 11.8097 5.25 12.5 5.25C13.1903 5.25 13.75 4.69034 13.75 4C13.75 3.30965 13.1903 2.75 12.5 2.75Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 14C13.6045 14 14.5 13.1045 14.5 12C14.5 10.8954 13.6045 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1045 11.3954 14 12.5 14Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 12C9.75 10.4812 10.9812 9.25 12.5 9.25C14.0188 9.25 15.25 10.4812 15.25 12C15.25 13.5188 14.0188 14.75 12.5 14.75C10.9812 14.75 9.75 13.5188 9.75 12ZM12.5 10.75C11.8097 10.75 11.25 11.3097 11.25 12C11.25 12.6903 11.8097 13.25 12.5 13.25C13.1903 13.25 13.75 12.6903 13.75 12C13.75 11.3097 13.1903 10.75 12.5 10.75Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 9.00034C18.2486 9.95694 19.4717 10.2847 20.4283 9.73239C21.3849 9.18009 21.7127 7.95694 21.1604 7.00034C20.6081 6.04374 19.3849 5.71599 18.4283 6.26829C17.4717 6.82059 17.144 8.04374 17.6963 9.00034Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0533 5.6189C19.3686 4.85949 21.0505 5.31016 21.8099 6.62547C22.5693 7.94079 22.1186 9.62263 20.8033 10.382C19.488 11.1414 17.8062 10.6908 17.0468 9.37547C16.2873 8.06015 16.738 6.37831 18.0533 5.6189ZM20.5109 7.37547C20.1657 6.77758 19.4012 6.57275 18.8033 6.91794C18.2054 7.26313 18.0006 8.02759 18.3458 8.62547C18.691 9.22336 19.4554 9.42819 20.0533 9.083C20.6512 8.73781 20.856 7.97335 20.5109 7.37547Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6963 15.0003C17.144 15.9569 17.4717 17.1801 18.4283 17.7324C19.3849 18.2847 20.6081 17.9569 21.1604 17.0003C21.7127 16.0437 21.3849 14.8206 20.4283 14.2683C19.4717 13.716 18.2486 14.0437 17.6963 15.0003Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0468 14.6255C17.8062 13.3102 19.488 12.8595 20.8033 13.6189C22.1186 14.3783 22.5693 16.0601 21.8099 17.3755C21.0505 18.6908 19.3686 19.1414 18.0533 18.382C16.738 17.6226 16.2873 15.9408 17.0468 14.6255ZM20.0533 14.9179C19.4554 14.5727 18.691 14.7776 18.3458 15.3755C18.0006 15.9733 18.2054 16.7378 18.8033 17.083C19.4012 17.4282 20.1657 17.2234 20.5109 16.6255C20.856 16.0276 20.6512 15.2631 20.0533 14.9179Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 18C11.3954 18 10.5 18.8954 10.5 20C10.5 21.1045 11.3954 22 12.5 22C13.6045 22 14.5 21.1045 14.5 20C14.5 18.8954 13.6045 18 12.5 18Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 20C9.75 18.4812 10.9812 17.25 12.5 17.25C14.0188 17.25 15.25 18.4812 15.25 20C15.25 21.5188 14.0188 22.75 12.5 22.75C10.9812 22.75 9.75 21.5188 9.75 20ZM12.5 18.75C11.8097 18.75 11.25 19.3097 11.25 20C11.25 20.6903 11.8097 21.25 12.5 21.25C13.1903 21.25 13.75 20.6903 13.75 20C13.75 19.3097 13.1903 18.75 12.5 18.75Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 15.0003C6.75162 14.0437 5.52847 13.716 4.57187 14.2683C3.61528 14.8206 3.28753 16.0437 3.83982 17.0003C4.3921 17.9569 5.61527 18.2847 6.57187 17.7324C7.52847 17.1801 7.85622 15.9569 7.30392 15.0003Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19687 13.6189C5.5122 12.8595 7.19404 13.3102 7.95344 14.6255C8.71286 15.9408 8.26219 17.6226 6.94688 18.382C5.63156 19.1414 3.94969 18.6908 3.1903 17.3755C2.43091 16.0601 2.88157 14.3783 4.19686 13.6189L4.19687 13.6189ZM4.94687 14.9179C4.349 15.2631 4.14416 16.0276 4.48934 16.6255C4.83452 17.2233 5.59899 17.4282 6.19687 17.083C6.79476 16.7378 6.99959 15.9733 6.65441 15.3755C6.30921 14.7776 5.54476 14.5727 4.94688 14.9179" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30392 9.00034C7.85622 8.04374 7.52847 6.82059 6.57187 6.26829C5.61527 5.71599 4.3921 6.04374 3.83982 7.00034C3.28753 7.95694 3.61528 9.18009 4.57187 9.73239C5.52847 10.2847 6.75162 9.95694 7.30392 9.00034Z" fill="#BC9357"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.1903 6.62547C3.94969 5.31015 5.63156 4.85949 6.94688 5.6189C8.26219 6.37831 8.71286 8.06014 7.95344 9.37547C7.19404 10.6908 5.5122 11.1414 4.19687 10.382L4.19686 10.382C2.88157 9.62263 2.43091 7.94079 3.1903 6.62547ZM6.19687 6.91794C5.59899 6.57275 4.83452 6.77759 4.48934 7.37546C4.14416 7.97334 4.349 8.73781 4.94688 9.083L4.57187 9.73252L4.94688 9.083C5.54476 9.42819 6.30921 9.22335 6.65441 8.62547C6.99959 8.02759 6.79476 7.26313 6.19687 6.91794ZM4.57187 9.73252L4.57044 9.735L4.57187 9.73252Z" fill="#BC9357"/>
                              </svg>
                            </div>
                            <div className="title-text">{child_item.item_title[curLang]}</div>
                          </div>
                        :''}
                        {(child_item.child_type == "only_text")?
                          <div className="content-desp" dangerouslySetInnerHTML={{__html: child_item.text_details[curLang]}}></div>
                        :''}
                        {(child_item.child_type == "btn_link")?
                          <div className="btn-link">
                          <Link to={child_item.btn_link[curLang]} className="btn">
                            <div className="btn-name">{child_item.btn_name[curLang]}</div>
                            <div className="btn-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.7482 3L20.3019 3.00051L20.4022 3.01443L20.501 3.04203L20.5613 3.0676C20.6405 3.10243 20.7148 3.15311 20.78 3.21831L20.8207 3.26235L20.8812 3.34383L20.9184 3.40885L20.9571 3.49917L20.9763 3.5633L20.9899 3.62724L20.9992 3.72043L20.9997 11.2539C20.9997 11.6681 20.6639 12.0039 20.2497 12.0039C19.87 12.0039 19.5562 11.7217 19.5066 11.3557L19.4997 11.2539L19.4993 5.558L4.27966 20.7833C4.01342 21.0496 3.59676 21.0738 3.30313 20.856L3.219 20.7834C2.9527 20.5172 2.92845 20.1005 3.14627 19.8069L3.21888 19.7227L18.4383 4.499L12.7482 4.5C12.3685 4.5 12.0547 4.21785 12.005 3.85177L11.9982 3.75C11.9982 3.3703 12.2803 3.05651 12.6464 3.00685L12.7482 3Z" fill="white"/>
                              </svg>
                            </div>
                          </Link>
                        </div>
                        :''}
                        {(child_item.child_type == "table_2_col")?
                          <div className="info-table-section">
                            {(child_item.table_item.table_title.left_col_title[curLang] && child_item.table_item.table_title.left_col_title[curLang] !== "")? 
                              <div className={`table-item item-title left-col ${(child_item.table_item.table_title.right_col_title[curLang] == "")?'all-width':''}`}>
                                <div className="title-text">{child_item.table_item.table_title.left_col_title[curLang]}</div>
                              </div>
                            :''}
                            {(child_item.table_item.table_title.right_col_title[curLang] && child_item.table_item.table_title.right_col_title[curLang] !== "")? 
                              <div className="table-item item-title right-col">
                                <div className="title-text">{child_item.table_item.table_title.right_col_title[curLang]}</div>
                              </div>
                            :''}
                            {
                              Object.keys(child_item.table_item).filter(key => key.startsWith('table_item_') && child_item.table_item[key].left_col_details.en !== "").map(key => {
                                const table_item_details = child_item.table_item[key];
                                return ([
                                  <div className="table-item item-title left-col">{table_item_details.left_col_details[curLang]}</div>,
                                  <div className="table-item right-col">{table_item_details.right_col_details[curLang]}</div>
                                ]);
                              })
                            }
                          </div>

                        :''}
                        
                      </div>
                    }):''}
                  </div>

                </div>
              </div>

            </div>
            
            { (pageRecommendItemList && pageRecommendItemList.length > 0) ?
              <div className="recommend-section">
                <div className="section-title">{t('recommended_journeys')}</div>
                <div className="recommend-item-list">
                      {pageRecommendItemList.map((recommendItem,idx)=>{
                        return <TravelIdeaTabItem key={idx} itemdetails={recommendItem.acf}></TravelIdeaTabItem>
                      })}
                </div>
              </div>
            :''}

          </div>
          <ContactUsForm></ContactUsForm>
        </div>
      ) : <div></div>
    );
  }
  
  export default TravelInfoDetails;