// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import './ExtraordinaryMoments.scss';
import i18n from 'i18next';

import {resources_callapi} from'../../common/CallApi';

// image
// import banner from '../../assets/aboutus/banner.png';

// components
import TravelIdeaTabItem from '../../components/TravelIdeaTabItem/TravelIdeaTabItem';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';


function ExtraordinaryMoments() {
    const { t } = useTranslation();
    let location = useLocation();
    // const navigate = useNavigate();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;
    const [pageContent, setPageContent] = React.useState({});
    const [pageRecommendItemList, setpageRecommendItemList] = React.useState([]);
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });


    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }

          
      const get_page_content = async () => {
        // const api_page_slug = categories+'_'+pagename;
        const api_page_slug = "extraordinary-moments";
        const api_page_content = await resources_callapi("about_us", api_page_slug);
        let render_content = [];
        let recommend_item_content = [];
        if (api_page_content.length > 0 && api_page_content.find((item) => item.slug == api_page_slug)) {
          render_content = api_page_content.find((item) => item.slug == api_page_slug).acf;
          console.log("🚀 ~ constget_page_content= ~ render_content:", render_content)
          recommend_item_content = await resources_callapi("travelbymontab","",`per_page=3&include=${render_content.recommend_item.join()}`);
          let moments_child_items = Object.keys(render_content).filter(key => key.startsWith('moments_child_item')).map(key => ({...render_content[key],child_name: key})).filter(item => item.title.en !== "");
          render_content['moments_child_items'] = moments_child_items;
          // const recommend_item_content = await resources_callapi("travelbymontab","",`per_page=3&include=${render_content.recommend_item.join()}`)
        }
        Promise.all([render_content,recommend_item_content]).then((content) => {
          setPageContent(content[0]);
          setpageRecommendItemList(content[1]);
        });
      }
      get_page_content();

    }, [curPath]);


    return (
      (pageContent && Object.keys(pageContent).length > 0) ? (
        <div className="page-container">
          <Helmet>
              <title>About us</title>
          </Helmet>
          <div className={`aboutus-moments-page-container`}>

            <div className="banner-section" style={(isMobile)?{backgroundImage: `url(`+ pageContent.banner_img.mobile +`)`}:{backgroundImage: `url(`+ pageContent.banner_img.desktop +`)`}}>
              <div className="banner-text-section">
                <div className="page-title">
                  <div className="page-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                    </svg>
                  </div>
                  <div className="page-name">{(pageContent.banner_page_title)?pageContent.banner_page_title[curLang]:''}</div>
                </div>
                <div className="sub-title">{(pageContent.banner_page_subtitle)?pageContent.banner_page_subtitle[curLang]:''}</div>
                {/* <div className="desp">{(pageContent.banner_page_desp)?pageContent.banner_page_desp[curLang]:''}</div> */}
              </div>
            </div>
            <ContactUs></ContactUs>

            <div className="page-info">
              <div className="info-title">{(pageContent.banner_page_subtitle)?pageContent.banner_page_subtitle[curLang]:''}</div>
              <div className="line-section"><div className="line"></div></div>
              <div className="desp">{(pageContent.banner_page_desp)?pageContent.banner_page_desp[curLang]:''}</div>
            </div>

            <div className="moment-list">
              {(pageContent.moments_child_items)?
                pageContent.moments_child_items.map((momentItem,idx)=>{
                  return <div className="moment-item" key={idx}>
                    <div className="item-img-container">
                      <img className="item-image" src={ momentItem.item_image} alt=""/>
                    </div>
                    <div className="item-details">
                      <div className="item-place">
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18.157 16.8838L16.97 18.0577C16.0952 18.9164 14.96 20.0203 13.5642 21.3699C12.6919 22.2132 11.3081 22.2131 10.436 21.3696L6.9449 17.9738C6.50614 17.543 6.13887 17.1797 5.84303 16.8838C2.44262 13.4834 2.44262 7.97025 5.84303 4.56984C9.24344 1.16943 14.7566 1.16943 18.157 4.56984C21.5574 7.97025 21.5574 13.4834 18.157 16.8838ZM14.5002 11.0015C14.5002 9.6207 13.3808 8.50133 12 8.50133C10.6192 8.50133 9.49982 9.6207 9.49982 11.0015C9.49982 12.3823 10.6192 13.5017 12 13.5017C13.3808 13.5017 14.5002 12.3823 14.5002 11.0015Z" fill="black"/>
                          </svg>
                        </div>
                        <div className="place-name">{momentItem.place[curLang]}</div>
                      </div>
                      <div className="item-title">{momentItem.title[curLang]}</div>
                      <div className="item-info">
                        <div className="info-author">
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M17.7543 13.9992C18.9963 13.9992 20.0032 15.0061 20.0032 16.2481V17.1665C20.0032 17.7399 19.8239 18.2989 19.4906 18.7654C17.9447 20.9287 15.4204 22.0004 12.0001 22.0004C8.57915 22.0004 6.05619 20.9282 4.51403 18.7639C4.18207 18.298 4.00366 17.7401 4.00366 17.1681V16.2481C4.00366 15.0061 5.01052 13.9992 6.25254 13.9992H17.7543ZM12.0001 2.00391C14.7615 2.00391 17.0001 4.24248 17.0001 7.00391C17.0001 9.76533 14.7615 12.0039 12.0001 12.0039C9.2387 12.0039 7.00012 9.76533 7.00012 7.00391C7.00012 4.24248 9.2387 2.00391 12.0001 2.00391Z" fill="black"/>
                            </svg>
                          </div>
                          <div className="author-name">{momentItem.author_name[curLang]}</div>
                        </div>
                        <div className="info-line"></div>
                        <div className="info-date">{momentItem.date}</div>
                      </div>
                      <div className="item-desp">{momentItem.item_details[curLang]}</div>
                    </div>
                  </div>
                })
              :''}
            </div>
            
            { (pageRecommendItemList && pageRecommendItemList.length > 0) ?
              <div className="recommend-section">
                <div className="section-title">{t('recommended_journeys')}</div>
                <div className="recommend-item-list">
                      {pageRecommendItemList.map((recommendItem,idx)=>{
                        return <TravelIdeaTabItem key={idx} itemdetails={recommendItem.acf}></TravelIdeaTabItem>
                      })}
                </div>
              </div>
            :''}

          </div>
          <ContactUsForm></ContactUsForm>
        </div>
        
      ) : <div></div>
    );
  }
  
  export default ExtraordinaryMoments;