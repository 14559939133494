import back_to_top from '../../assets/back_to_top.png';

import React, { useState } from 'react';
import './BackToTop.scss'

function BackToTop() {
    
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

    return (
      <div className="backtotop-container" style={{display: visible ? 'inline' : 'none'}}>
        <img src={back_to_top} alt="" className="backtotop-btn" onClick={scrollToTop}/>
      </div>
    );
  }
  
  export default BackToTop;