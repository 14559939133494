
import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import './ContactUsBtn.scss';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";




function ContactUs() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = React.useState(false);
    
    
    const [menuscroll, setMenuScroll] = useState(false)
    const [curLang, setCurLang] = useState(i18n.resolvedLanguage)
    
    const toggleMenuScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 420){
        setMenuScroll(true)
      } 
      else if (scrolled <= 420){
        setMenuScroll(false)
      }
    };
    
    window.addEventListener('scroll', toggleMenuScroll);

    const scrollToForm = ()=>{
      document.getElementById("contactus-form").scrollIntoView({behavior: "smooth"});
    }


    return (
      <div className="contactus-container" style={{display: menuscroll ? 'flex' : 'none'}}>
        <div className="contactus-section">
          <div className="section-title">{t('contact_us')}</div>
          <div className="icon-section">
            <div className="inbox-section">
              <div onClick={scrollToForm} className="ext-link-item form-item">
                <div className="inbox-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M4.01592 2.84703C4.08837 2.49924 4.39489 2.25 4.75015 2.25H19.7502C20.1054 2.25 20.4119 2.49924 20.4844 2.84703L22.9844 14.847C23.0689 15.2525 22.8086 15.6498 22.4031 15.7342C21.9976 15.8187 21.6004 15.5585 21.5159 15.153L19.1403 3.75H5.36L2.98439 15.153C2.89991 15.5585 2.50269 15.8187 2.09719 15.7342C1.69168 15.6498 1.43143 15.2525 1.51592 14.847L4.01592 2.84703Z" fill="white"/>
                    <path d="M1.50015 15C1.50015 14.5858 1.83594 14.25 2.25015 14.25H7.7047C8.03513 14.25 8.32664 14.4663 8.42246 14.7825L9.17019 17.25H15.3301L16.0778 14.7825C16.1737 14.4663 16.4652 14.25 16.7956 14.25H22.2502C22.6644 14.25 23.0002 14.5858 23.0002 15V21.5C23.0002 21.9142 22.6644 22.25 22.2502 22.25H2.25015C1.83594 22.25 1.50015 21.9142 1.50015 21.5V15ZM3.00015 15.75V20.75H21.5002V15.75H17.352L16.6043 18.2175C16.5085 18.5337 16.217 18.75 15.8865 18.75H8.61379C8.28335 18.75 7.99185 18.5337 7.89602 18.2175L7.14829 15.75H3.00015Z" fill="white"/>
                    <path d="M9.00015 7C9.00015 6.58579 9.33594 6.25 9.75015 6.25H14.7502C15.1644 6.25 15.5002 6.58579 15.5002 7C15.5002 7.41421 15.1644 7.75 14.7502 7.75H9.75015C9.33594 7.75 9.00015 7.41421 9.00015 7Z" fill="white"/>
                    <path d="M7.50015 11C7.50015 10.5858 7.83594 10.25 8.25015 10.25H16.2502C16.6644 10.25 17.0002 10.5858 17.0002 11C17.0002 11.4142 16.6644 11.75 16.2502 11.75H8.25015C7.83594 11.75 7.50015 11.4142 7.50015 11Z" fill="white"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className="phone-section">
              <a href={`tel:${t('info.phone_num').replace(/[\(\)-\s]/g,'')}`} target="_blank"  rel="noreferrer" className="ext-link-item phone-item">
                <div className="phone-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M5.85921 4.7196C5.05292 4.7196 4.54997 5.44209 4.78031 6.12473C5.60619 8.57237 7.1452 12.2544 9.44039 14.5496C11.7355 16.8448 15.4176 18.3838 17.8653 19.2097C18.5479 19.44 19.2704 18.9371 19.2704 18.1308V15.6454C19.2704 15.5565 19.2222 15.4746 19.1445 15.4314L16.9796 14.2278C16.9089 14.1885 16.8234 14.1866 16.7511 14.2228L14.4423 15.3771C14.298 15.4493 14.1339 15.4719 13.9753 15.4415L14.1137 14.7199C13.9753 15.4415 13.9753 15.4415 13.9753 15.4415L13.9732 15.4411L13.9705 15.4406L13.9631 15.4391L13.9409 15.4345C13.9229 15.4307 13.8986 15.4253 13.8683 15.4181C13.8079 15.4038 13.7238 15.3825 13.6202 15.3526C13.4133 15.2929 13.1266 15.1984 12.7935 15.0564C12.13 14.7737 11.2639 14.2948 10.4795 13.5105C9.69529 12.7262 9.21504 11.8589 8.93099 11.1945C8.78833 10.8608 8.69314 10.5736 8.63288 10.3663C8.60271 10.2625 8.58116 10.1783 8.56671 10.1178C8.55949 10.0875 8.55403 10.0632 8.55014 10.0452L8.54548 10.023L8.54399 10.0157L8.54344 10.0129L8.54322 10.0118C8.54322 10.0118 8.54303 10.0108 9.26431 9.87052L8.54322 10.0118C8.51228 9.8527 8.53461 9.68687 8.60708 9.54191L9.7616 7.23283C9.79773 7.16059 9.79593 7.07508 9.75669 7.00439L8.55832 4.84571C8.51508 4.76783 8.43305 4.7196 8.34415 4.7196H5.85921ZM10.0437 9.9548L10.0441 9.95603C10.0903 10.1151 10.1663 10.3454 10.2823 10.6168C10.5156 11.1624 10.9027 11.8553 11.5187 12.4713M11.5187 12.4713C12.1346 13.0872 12.8258 13.4728 13.3696 13.7045C13.64 13.8197 13.8695 13.895 14.0278 13.9407L16.0938 12.9084C16.6002 12.6551 17.1989 12.6683 17.6937 12.9433L19.8585 14.1469C20.4027 14.4494 20.74 15.023 20.74 15.6454V18.1308C20.74 19.8558 19.1113 21.1811 17.3955 20.6021C14.9171 19.7659 10.9526 18.1402 8.40124 15.5888C5.84981 13.0374 4.22409 9.07293 3.38784 6.59458C2.80889 4.87874 4.13421 3.25 5.85921 3.25H8.34415C8.96702 3.25 9.54082 3.58784 9.84314 4.1323L11.0416 6.2911C11.3161 6.7857 11.3291 7.38401 11.076 7.8901L10.0437 9.9548" fill="white"/>
                  </svg>
                </div>
              </a>
            </div>
            <div className="line"></div>
            <div className="whatsapp-section">
              <a href="https://wa.me/98137274" target="_blank"  rel="noreferrer" className="ext-link-item phone-item">
                <div className="whatsapp-icon">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="24" viewBox="0 0 25 24">
                  <path class="cls-1" d="M20.2,8.7c-.4-.9-1-1.8-1.8-2.5-.8-.7-1.7-1.3-2.7-1.7h0c-1-.4-2.1-.6-3.3-.6s-2.2.2-3.3.6h0c-1,.4-1.9,1-2.7,1.7-.8.7-1.4,1.6-1.8,2.5-.4,1-.7,2-.7,3,0,1.8.6,3.5,1.8,4.8.2.3.3.6.3,1l-.5,2.5,2.6-1.1c.3-.1.6-.1.9,0,1,.4,2.1.6,3.3.6s2.2-.2,3.3-.6c1-.4,1.9-1,2.7-1.7.8-.7,1.4-1.6,1.8-2.5.4-1,.7-2,.7-3h0c0-1.1-.2-2.1-.6-3Z"/>
                  <path class="cls-1" d="M17,13.8c-.3-.2-.6-.3-.8-.4-.4-.2-.7-.3-.9-.4-.1,0-.4-.2-.5,0-.3.3-.7,1-1,1.2-.9-.2-1.8-.8-2.4-1.4-.3-.3-.8-1.1-1-1.3,0-.2.4-.5.5-.7.5-.5.1-.8,0-1-.1-.2-.3-.6-.4-.9-.1-.2-.2-.5-.4-.6-1-.5-1.6.5-1.8,1-1.4,3.4,7.1,9.9,8.8,5.4,0-.4,0-.5,0-.7Z"/>
                </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ContactUs;