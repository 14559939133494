import React, { useState, useEffect,useRef  } from 'react';
// import { Link } from 'react-router-dom';
import i18n from 'i18next';
import './MapTab.scss'
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import {resources_callapi} from'../../common/CallApi';

function MapTab() {
    const { t } = useTranslation();
    const curLang = i18n.resolvedLanguage;
    const [slideIndex, setSlideIndex] = useState(0);
    const [MapContent, setMapContent] = React.useState();
    // const [state, setState] = React.useState({ selected: "m1-3" });

    

    useEffect(() => {
      const get_map_data = async () => {
        const api_map_data = await resources_callapi("migration_map");
        setMapContent(api_map_data);
      }
      get_map_data();

    }, [curLang]);

    var map_slider_settings = {
      dots: true,
      fade: true,
      infinite: true,
      autoplay:false,
      autoplaySpeed:3000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots:false,
      arrows:false,
      beforeChange: (current, next) => setSlideIndex(next)
    };

    
    let sliderMap = useRef();
    const next = () => {
      sliderMap.slickNext();
    };
    const previous = () => {
      sliderMap.slickPrev();
    };
    

    const handleTabChangeMb = (event) => {
      // setTabChange({selected:event.target.value});
      sliderMap.slickGoTo(event.target.value);
    }

    
        console.log("🚀 ~ constget_map_data= ~ MapContent:", MapContent)
   
    return (
     (MapContent) ? (
      <div className="map-tab-section">
        <div className="tab-item-container">
          {MapContent.map((item,index) => {
            return <button key={index} className={`tab-item ${index == slideIndex ? 'active':''}`} onClick={()=>sliderMap.slickGoTo(index)}>{item.acf.item_title[curLang]}</button>;
          })}
        </div>
        <div className="tab-item-container-mb">
          <select className="item-selection" name="travalTabMb" id="travalTabMb" onChange={handleTabChangeMb}> 
          {MapContent.map((item,index) => { 
            return <option id={item.tab_id} key={index} value={index}  selected={index == slideIndex ?true:false}>{item.acf.item_title[curLang]}</option>
          })}
          </select>
        </div>
        <div className={`tab-map-details-container`}> 
          <Slider
            ref={(slider) => {
              sliderMap = slider;
            }}
            {...map_slider_settings}
          >
            {MapContent.map((sliderItem,sliderItemIndex) => {
              return (
                <div key={sliderItemIndex}>
                  <div className="tab-map-content-slider-item" id={sliderItemIndex}>
                    <div className="map-info-section">
                      <img className="map-info-top-img" src={sliderItem.acf.info_top_img} alt="" />
                      <div className="map-info-container">
                        <div className="top-info-section">
                          <div className="map-pin">{sliderItem.acf.map_point[curLang]}</div>
                          <div className="info-title">
                            {sliderItem.acf.info_title[curLang]}
                          </div>
                        </div>
                        <div className="slider-click-section">
                          <button className="prev-slider-btn slider-btn" onClick={()=>previous()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M15.5303 4.21967C15.8232 4.51256 15.8232 4.98744 15.5303 5.28033L8.81066 12L15.5303 18.7197C15.8232 19.0126 15.8232 19.4874 15.5303 19.7803C15.2374 20.0732 14.7626 20.0732 14.4697 19.7803L7.21967 12.5303C6.92678 12.2374 6.92678 11.7626 7.21967 11.4697L14.4697 4.21967C14.7626 3.92678 15.2374 3.92678 15.5303 4.21967Z" fill="white"/>
                            </svg>
                          </button>
                          <button className="next-slider-btn slider-btn" onClick={()=>next()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M8.46967 4.21967C8.17678 4.51256 8.17678 4.98744 8.46967 5.28033L15.1893 12L8.46967 18.7197C8.17678 19.0126 8.17678 19.4874 8.46967 19.7803C8.76256 20.0732 9.23744 20.0732 9.53033 19.7803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L9.53033 4.21967C9.23744 3.92678 8.76256 3.92678 8.46967 4.21967Z" fill="white"/>
                            </svg>
                          </button>
                        </div>
                        <div className="info-desp">{sliderItem.acf.info_desp[curLang]}</div>
                      </div>
                    </div>
                    <div className="map-img-section">
                      <img src={sliderItem.acf.map_img} alt="" />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>):<div></div>
    );
  }
  
  export default MapTab;